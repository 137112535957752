import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MineralManagement = () => (
  <Layout>
    <SEO title="Mineral Management" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Mineral Management</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Need help in managing minerals? Gray Hawk Land Solutions provide
              unique and dependable solutions for a wide variety of needs.
            </p>
            <p>
              We can guide you throughout the whole mineral setup and
              maintenance process. Assets will be distributed properly, the
              necessary assessments will be completed, and our team of
              professionals will always be ready to help you solve any problems.
            </p>
            <p>
              Included in our list of partners are leading industry experts and
              software solutions to make mineral management less complex. Reach
              out to us any time to know how our system can work for you.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2>Our Services</h2>
            <p className="font-weight-bold mt-3">
              Here are just a few of the mineral management services we can
              provide:
            </p>
          </div>
          <div className="col-lg-10 mx-auto">
            <ul style={{ columns: 2 }}>
              <li>Division Order Decks & Maintenance</li>
              <li>Leases & Contracts</li>
              <li>Legal Services</li>
              <li>Properties/Deeds/Royalties</li>
              <li>Represent Mineral Owners</li>
              <li>Revenue Check Statement Extraction</li>
              <li>Tracts</li>
              <li>Wells</li>
            </ul>
          </div>
          <div className="col-lg-12">
            <p className="font-weight-bold">Aside from that, we also cover:</p>
          </div>
          <div className="col-lg-10 mx-auto">
            <ul style={{ columns: 2 }}>
              <li>Correspondence and Communication</li>
              <li>Dashboard Reporting</li>
              <li>Division Orders Maintenance and Verification</li>
              <li>Lease Negotiations and Maintenance</li>
              <li>Revenue Statements</li>
              <li>Mineral Process Automation</li>
              <li>Well Search and New Well Setup</li>
              <li>Wells Review and Payments</li>
            </ul>
          </div>
          <div className="col-lg-12">
            <p>
              We have the technology, the expertise, and the desire to serve you
              to the best of our abilities.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default MineralManagement
